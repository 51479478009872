/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, {useMemo, useState} from 'react';
import {Button, Grid} from '@material-ui/core';
import BadgeIcon from '@material-ui/icons/CallToAction';
import {
    EntityApiDefinitionCard,
    EntityConsumedApisCard,
    EntityConsumingComponentsCard,
    EntityHasApisCard,
    EntityProvidedApisCard,
    EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {EntityBadgesDialog} from '@backstage/plugin-badges';
import {
    EntityAboutCard,
    EntityDependsOnComponentsCard,
    EntityDependsOnResourcesCard,
    EntityHasComponentsCard,
    EntityHasResourcesCard,
    EntityHasSubcomponentsCard,
    EntityHasSystemsCard,
    EntityLayout,
    EntityLinksCard,
    EntityOrphanWarning,
    EntityProcessingErrorsPanel,
    EntityRelationWarning,
    EntitySwitch,
    hasCatalogProcessingErrors,
    hasRelationWarnings,
    isComponentType,
    isKind,
    isOrphan,
} from '@backstage/plugin-catalog';
import {
    EntityGithubActionsContent,
    EntityRecentGithubActionsRunsCard,
    isGithubActionsAvailable,
} from '@backstage/plugin-github-actions';
import {
    EntityGroupProfileCard,
    EntityMembersListCard,
    EntityOwnershipCard,
    EntityUserProfileCard,
} from '@backstage/plugin-org';
import {EntityTechdocsContent, isTechDocsAvailable} from '@backstage/plugin-techdocs';
import {EntityTodoContent} from '@backstage/plugin-todo';
import {EmptyState} from '@backstage/core-components';
import {EntityCatalogGraphCard} from '@backstage/plugin-catalog-graph';
import {EntityStorybookCard, StorybookPage, isStorybookAvailable} from '@internal/plugin-storybook';
import {Cdn77Page, isCDN77Available} from '@internal/plugin-cdn77';
import {EntitySonarQubeCard} from '@backstage/plugin-sonarqube';
import {EntityJiraOverviewCard, isJiraAvailable} from '@roadiehq/backstage-plugin-jira';
import {EntitySentryContent, isSentryAvailable} from '@backstage/plugin-sentry';
import { isGitlabAvailable, EntityGitlabContent } from '@loblaw/backstage-plugin-gitlab';

const EntityLayoutWrapper = (props: { children?: React.ReactNode }) => {
    const [badgesDialogOpen, setBadgesDialogOpen] = useState(false);

    const extraMenuItems = useMemo(() => {
        return [
            {
                title: 'Badges',
                Icon: BadgeIcon,
                onClick: () => setBadgesDialogOpen(true),
            },
        ];
    }, []);

    return (
        <>
            <EntityLayout UNSTABLE_extraContextMenuItems={extraMenuItems}>
                {props.children}
            </EntityLayout>
            <EntityBadgesDialog
                open={badgesDialogOpen}
                onClose={() => setBadgesDialogOpen(false)}
            />
        </>
    );
};

const entityWarningContent = (
    <>
        <EntitySwitch>
            <EntitySwitch.Case if={isOrphan}>
                <Grid item xs={12}>
                    <EntityOrphanWarning/>
                </Grid>
            </EntitySwitch.Case>
        </EntitySwitch>

        <EntitySwitch>
            <EntitySwitch.Case if={hasRelationWarnings}>
                <Grid item xs={12}>
                    <EntityRelationWarning/>
                </Grid>
            </EntitySwitch.Case>
        </EntitySwitch>

        <EntitySwitch>
            <EntitySwitch.Case if={hasCatalogProcessingErrors}>
                <Grid item xs={12}>
                    <EntityProcessingErrorsPanel/>
                </Grid>
            </EntitySwitch.Case>
        </EntitySwitch>
    </>
);

const cicdContent = (
    // This is an example of how you can implement your company's logic in entity page.
    // You can for example enforce that all components of type 'service' should use GitHubActions
    <EntitySwitch>
        <EntitySwitch.Case if={isGithubActionsAvailable}>
            <EntityGithubActionsContent />
        </EntitySwitch.Case>

        <EntitySwitch.Case if={isGitlabAvailable}>
            <EntityGitlabContent />
        </EntitySwitch.Case>

        <EntitySwitch.Case>
            <EmptyState
                title="No CI/CD available for this entity"
                missing="info"
                description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
                action={
                    <Button
                        variant="contained"
                        color="primary"
                        href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
                    >
                        Read more
                    </Button>
                }
            />
        </EntitySwitch.Case>
    </EntitySwitch>
);


const cicdCard = (
    <EntitySwitch>
        <EntitySwitch.Case if={isGithubActionsAvailable}>
            <Grid item xs={12} sm={6}>
                <EntityRecentGithubActionsRunsCard limit={4} variant="gridItem"/>
            </Grid>
        </EntitySwitch.Case>
    </EntitySwitch>
);

const overviewContent = (
    <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item xs={12} md={6}>
            <EntityAboutCard variant="gridItem"/>
        </Grid>

        <Grid item md={6} xs={12}>
            <EntityCatalogGraphCard variant="gridItem" height={400}/>
        </Grid>

        <Grid item xs={12} md={4} sm={6}>
            <EntityLinksCard/>
        </Grid>

        {cicdCard}

        <Grid item xs={12} md={6}>
            <EntityHasSubcomponentsCard variant="gridItem"/>
        </Grid>

        <Grid item md={4} xs={12}>
            <EntityLinksCard />
            <EntitySwitch>
                <EntitySwitch.Case if={isStorybookAvailable}>
                    <EntityStorybookCard/>
                </EntitySwitch.Case>
            </EntitySwitch>
            <EntityHasSubcomponentsCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
            <EntitySwitch>
                <EntitySwitch.Case>
                    <EntitySonarQubeCard variant="gridItem" />
                </EntitySwitch.Case>
            </EntitySwitch>
        </Grid>
        <EntitySwitch>
            <EntitySwitch.Case if={isJiraAvailable}>
                <Grid item md={6}>
                    <EntityJiraOverviewCard />
                </Grid>
            </EntitySwitch.Case>
        </EntitySwitch>


    </Grid>
);

const serviceEntityPage = (
    <EntityLayoutWrapper>
        <EntityLayout.Route path="/" title="Overview">
            {overviewContent}
        </EntityLayout.Route>

        <EntityLayout.Route path="/ci-cd" title="CI/CD">
            {cicdContent}
        </EntityLayout.Route>

        <EntityLayout.Route path="/api" title="API">
            <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={12} md={6}>
                    <EntityProvidedApisCard/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <EntityConsumedApisCard/>
                </Grid>
            </Grid>
        </EntityLayout.Route>

        <EntityLayout.Route path="/dependencies" title="Dependencies">
            <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={12} md={6}>
                    <EntityDependsOnComponentsCard variant="gridItem"/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <EntityDependsOnResourcesCard variant="gridItem"/>
                </Grid>
            </Grid>
        </EntityLayout.Route>

        <EntityLayout.Route path="/docs" title="Docs" if={isTechDocsAvailable}>
            <EntityTechdocsContent/>
        </EntityLayout.Route>

        <EntityLayout.Route path="/sentry" title="Sentry" if={isSentryAvailable}>
            <EntitySentryContent/>
        </EntityLayout.Route>

        <EntityLayout.Route path="/todos" title="TODOs">
            <EntityTodoContent/>
        </EntityLayout.Route>

        <EntityLayout.Route path="/cdn77" title="CDN77" if={isCDN77Available}>
            <Cdn77Page/>
        </EntityLayout.Route>

    </EntityLayoutWrapper>
);

const websiteEntityPage = (
    <EntityLayoutWrapper>
        <EntityLayout.Route path="/" title="Overview">
            {overviewContent}
        </EntityLayout.Route>

        <EntityLayout.Route path="/ci-cd" title="CI/CD">
            {cicdContent}
        </EntityLayout.Route>

        <EntityLayout.Route path="/dependencies" title="Dependencies">
            <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={12} md={6}>
                    <EntityDependsOnComponentsCard variant="gridItem"/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <EntityDependsOnResourcesCard variant="gridItem"/>
                </Grid>
            </Grid>
        </EntityLayout.Route>

        <EntityLayout.Route path="/docs" title="Docs" if={isTechDocsAvailable}>
            <EntityTechdocsContent/>
        </EntityLayout.Route>

        <EntityLayout.Route path="/todos" title="TODOs">
            <EntityTodoContent/>
        </EntityLayout.Route>

        <EntityLayout.Route path="/cdn77" title="CDN77" if={isCDN77Available}>
            <Cdn77Page/>
        </EntityLayout.Route>

    </EntityLayoutWrapper>
);

const defaultEntityPage = (
    <EntityLayoutWrapper>
        <EntityLayout.Route path="/" title="Overview">
            {overviewContent}
        </EntityLayout.Route>

        <EntityLayout.Route path="/docs" title="Docs" if={isTechDocsAvailable}>
            <EntityTechdocsContent/>
        </EntityLayout.Route>

        <EntityLayout.Route path="/storybook" title="Storybook" if={isStorybookAvailable}>
            <StorybookPage/>
        </EntityLayout.Route>

        <EntityLayout.Route path="/cdn77" title="CDN77" if={isCDN77Available}>
            <Cdn77Page/>
        </EntityLayout.Route>

        <EntityLayout.Route path="/todos" title="TODOs">
            <EntityTodoContent/>
        </EntityLayout.Route>
    </EntityLayoutWrapper>
);


const componentPage = (
    <EntitySwitch>
        <EntitySwitch.Case if={isComponentType('service')}>
            {serviceEntityPage}
        </EntitySwitch.Case>

        <EntitySwitch.Case if={isComponentType('website')}>
            {websiteEntityPage}
        </EntitySwitch.Case>

        <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
    </EntitySwitch>
);

const apiPage = (
    <EntityLayoutWrapper>
        <EntityLayout.Route path="/" title="Overview">
            <Grid container spacing={3}>
                {entityWarningContent}
                <Grid item xs={12} md={6}>
                    <EntityAboutCard variant="gridItem"/>
                </Grid>
                <Grid item md={6} xs={12}>
                    <EntityCatalogGraphCard variant="gridItem" height={400}/>
                </Grid>
                <Grid container item md={12}>
                    <Grid item xs={12} md={6}>
                        <EntityProvidingComponentsCard/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <EntityConsumingComponentsCard/>
                    </Grid>
                </Grid>
            </Grid>
        </EntityLayout.Route>

        <EntityLayout.Route path="/definition" title="Definition">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <EntityApiDefinitionCard/>
                </Grid>
            </Grid>
        </EntityLayout.Route>
    </EntityLayoutWrapper>
);

const userPage = (
    <EntityLayoutWrapper>
        <EntityLayout.Route path="/" title="Overview">
            <Grid container spacing={3}>
                {entityWarningContent}
                <Grid item xs={12} md={6}>
                    <EntityUserProfileCard variant="gridItem"/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <EntityOwnershipCard variant="gridItem"/>
                </Grid>
            </Grid>
        </EntityLayout.Route>
    </EntityLayoutWrapper>
);

const groupPage = (
    <EntityLayoutWrapper>
        <EntityLayout.Route path="/" title="Overview">
            <Grid container spacing={3}>
                {entityWarningContent}
                <Grid item xs={12} md={6}>
                    <EntityGroupProfileCard variant="gridItem"/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <EntityOwnershipCard variant="gridItem"/>
                </Grid>
                <Grid item xs={12}>
                    <EntityMembersListCard/>
                </Grid>
            </Grid>
        </EntityLayout.Route>
    </EntityLayoutWrapper>
);

const systemPage = (
    <EntityLayoutWrapper>
        <EntityLayout.Route path="/" title="Overview">
            <Grid container spacing={3} alignItems="stretch">
                {entityWarningContent}
                <Grid item xs={12} md={6}>
                    <EntityAboutCard variant="gridItem"/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <EntityHasComponentsCard variant="gridItem"/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <EntityHasApisCard variant="gridItem"/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <EntityHasResourcesCard variant="gridItem"/>
                </Grid>
            </Grid>
        </EntityLayout.Route>
        <EntityLayout.Route path="/diagram" title="Diagram">
            <EntityCatalogGraphCard/>
        </EntityLayout.Route>
    </EntityLayoutWrapper>
);

const domainPage = (
    <EntityLayoutWrapper>
        <EntityLayout.Route path="/" title="Overview">
            <Grid container spacing={3} alignItems="stretch">
                {entityWarningContent}
                <Grid item xs={12} md={6}>
                    <EntityAboutCard variant="gridItem"/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <EntityHasSystemsCard variant="gridItem"/>
                </Grid>
            </Grid>
        </EntityLayout.Route>
    </EntityLayoutWrapper>
);

export const entityPage = (
    <EntitySwitch>
        <EntitySwitch.Case if={isKind('component')} children={componentPage}/>
        <EntitySwitch.Case if={isKind('api')} children={apiPage}/>
        <EntitySwitch.Case if={isKind('group')} children={groupPage}/>
        <EntitySwitch.Case if={isKind('user')} children={userPage}/>
        <EntitySwitch.Case if={isKind('system')} children={systemPage}/>
        <EntitySwitch.Case if={isKind('domain')} children={domainPage}/>

        <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
    </EntitySwitch>
);
