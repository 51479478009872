import { Entity } from '@backstage/catalog-model';

export const WEB_PROJECT_KEY_ANNOTATION = 'storybook.js.org/web';
export const IOS_PROJECT_KEY_ANNOTATION = 'storybook.js.org/ios';
export const ANDROID_PROJECT_KEY_ANNOTATION = 'storybook.js.org/android';

export const isWebAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[WEB_PROJECT_KEY_ANNOTATION]);

export const useWebKey = (entity: Entity) => {
  return entity?.metadata.annotations?.[WEB_PROJECT_KEY_ANNOTATION] ?? '';
};

export const isIosAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[IOS_PROJECT_KEY_ANNOTATION]);

export const useIosKey = (entity: Entity) => {
  return entity?.metadata.annotations?.[IOS_PROJECT_KEY_ANNOTATION] ?? '';
};

export const isAndroidAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[ANDROID_PROJECT_KEY_ANNOTATION]);

export const useAndroidKey = (entity: Entity) => {
  return entity?.metadata.annotations?.[ANDROID_PROJECT_KEY_ANNOTATION] ?? '';
};