import {
    TechRadarApi,
    TechRadarLoaderResponse,
  } from '@backstage/plugin-tech-radar';

  const techRadar:TechRadarLoaderResponse = {
    quadrants: [
        { id: 'techniques', name: 'Techniques' },
        { id: 'infrastructure', name: 'Infrastructure' },
        { id: 'datamanagement', name: 'Data Management' },
        { id: 'languages-and-frameworks', name: 'Languages & Frameworks' }
    ],
    rings: [
        { id: 'use', name: 'USE', color: '#93c47d' },
        { id: 'trial', name: 'TRIAL', color: '#93d2c2' },
        { id: 'assess', name: 'ASSESS', color: '#fbdb84' },
        { id: 'hold', name: 'HOLD', color: '#efafa9' }
    ],
    entries: [
        {
            key: 'kubernetes',
            id: 'kubernetes',
            title: 'Kubernetes',
            quadrant: 'infrastructure',
            timeline: [
                {
                    moved: 0,
                    ringId: 'use',
                    date: new Date('2021-05-26'),
                    description:
                        'Kubernetes as main Orchestrator for our Microservices',
                },
            ],
        },
        {
            key: 'distroless-docker',
            id: 'distroless-docker',
            title: 'Distroless Docker images',
            quadrant: 'techniques',
            timeline: [
                {
                    moved: 0,
                    ringId: 'assess',
                    date: new Date('2021-05-26'),
                    description:
                        'When building **Docker** images for our applications, we\'re often concerned with two things: the security and the size of the image. Traditionally, we\'ve used container security scanning tools to detect and patch common vulnerabilities and exposures and small distributions such as Alpine Linux to address the image size and distribution performance. We\'ve now gained more experience with distroless Docker images and are ready to recommend this approach as another important security precaution for containerized applications. Distroless Docker images reduce the footprint and dependencies by doing away with a full operating system distribution. This technique reduces security scan noise and the application attack surface. There are fewer vulnerabilities that need to be patched and as a bonus, these smaller images are more efficient. Google has published a set of distroless container images for different languages. You can create distroless application images using the Google build tool Bazel or simply use multistage Dockerfiles. Note that distroless containers by default don\'t have a shell for debugging. However, you can easily find debug versions of distroless containers online, including a BusyBox shell. Distroless Docker images is a technique pioneered by Google and, in our experience, is still largely confined to Google-generated images. We\'re hoping that the technique catches on beyond this ecosystem.',
                },
            ],
        },
        {
            key: 'browser-tailored-polyfills',
            id: 'browser-tailored-polyfills',
            title: 'Browser-tailored polyfills',
            quadrant: 'techniques',
            timeline: [
                {
                    moved: 0,
                    ringId: 'assess',
                    date: new Date('2021-05-26'),
                    description:
                        'Polyfills are extremely useful to help the web evolve, providing substitute implementations of modern features for browsers that don\'t implement them (yet). Too often, though, web applications ship polyfills to browsers that don\'t need them, which causes unnecessary download and parsing overhead. The situation is becoming more pronounced now as only a few rendering engines remain and the bulk of the polyfills target only one of them: the Trident renderer in IE11. Further, market share of IE11 is dwindling with support ending in less than a year. We therefore suggest that you make use of browser-tailored polyfills, shipping only necessary polyfills to a given browser. This technique can even be implemented as a service with Polyfill.io.',
                },
            ],
        },
        {
            key: 'apollo-federation',
            id: 'apollo-federation',
            title: 'Apollo Federation',
            quadrant: 'techniques',
            timeline: [
                {
                    moved: 0,
                    ringId: 'hold',
                    date: new Date('2021-05-26'),
                    description:
                        'When we first covered GraphQLin the Radar, we cautioned that its misuse can lead to antipatterns which, in the long run, has more disadvantages than benefits. Nevertheless, we’ve seen an increasing interest in GraphQL among our teams because of its ability to aggregate information from different resources. This time we want to caution you about using Apollo Federation and its  strong support for a single unified data graph for your company. Even though at first glance the idea of having ubiquitous concepts across the organization is tempting, we have to take into account previous similar attempts in the industry — such as MDM and canonical data model among others — that have exposed the pitfalls of this approach. The challenges can be significant, especially when the domain we find ourselves in is complex enough to create a unique unified model.',
                },
            ],
        },
        {
            links: [{ title: 'Jaeger', url: 'https://jaeger.boom.sport1.it/search' }],
            key: 'jaeger',
            id: 'jaeger',
            title: 'Jaeger',
            quadrant: 'infrastructure',
            timeline: [
                {
                    moved: 0,
                    ringId: 'assess',
                    date: new Date('2021-05-26'),
                    description:
                        'Use Jaeger to trace the performance of our microservices',
                },
            ],
        },
        {
            key: 'aws-lambda',
            id: 'aws-lambda',
            title: 'AWS Lambda',
            quadrant: 'infrastructure',
            timeline: [
                {
                    moved: 0,
                    ringId: 'trial',
                    date: new Date('2021-05-26'),
                    description:
                        'We use Lambda to upload from s3 bucket to rabbitMQ (for example for SID articles)',
                },
            ],
        },
        {
            key: 'mysql',
            id: 'mysql',
            title: 'MySQL',
            quadrant: 'datamanagement',
            timeline: [
                {
                    moved: 0,
                    ringId: 'hold',
                    date: new Date('2021-05-26'),
                    description:
                        'Our MySQL Cluster was never updated, doesn\' use a multi-location and sometimes need to be restarted. we should find a better way to handle it',
                },
            ],
        },
        {
            links: [{ title: 'MySQL Operator', url: 'https://github.com/presslabs/mysql-operator' }],
            key: 'mysql-operator',
            id: 'mysql-operator',
            title: 'MySQL Operator',
            quadrant: 'datamanagement',
            timeline: [
                {
                    moved: 0,
                    ringId: 'assess',
                    date: new Date('2021-05-26'),
                    description:
                        'We should try with a cluster operator, to save time for backups/restores and user-management',
                },
            ],
        },
        {
            key: 'aws-s3',
            id: 'aws-s3',
            title: 'AWS S3',
            quadrant: 'datamanagement',
            timeline: [
                {
                    moved: 0,
                    ringId: 'use',
                    date: new Date('2021-05-26'),
                    description:
                        'Our main source for storage buckets. Used for Images, Videos and Raw material',
                },
            ],
        },
        {
            links: [{ title: 'Elastic', url: 'https://www.elastic.co' }],
            key: 'elasticsearch',
            id: 'elasticsearch',
            title: 'Elasticsearch',
            quadrant: 'datamanagement',
            timeline: [
                {
                    moved: 0,
                    ringId: 'use',
                    date: new Date('2021-05-26'),
                    description:
                        'We have installed in cluster an ElasticSearch, it can be used as main source for all our domain models',
                },
            ],
        },
        {
            links: [{ title: 'Kafka', url: 'https://kafka.apache.org' }],
            key: 'kafka',
            id: 'kafka',
            title: 'Kafka',
            quadrant: 'datamanagement',
            timeline: [
                {
                    moved: 0,
                    ringId: 'hold',
                    date: new Date('2021-05-26'),
                    description:
                        'Kafka is great, but we haven\'t found out a way to manage it and a good use case for our Ingest',
                },
            ],
        },
        {
            links: [{ title: 'RabbitMQ', url: 'https://www.rabbitmq.com' }],
            key: 'rabbitmq',
            id: 'rabbitmq',
            title: 'RabbitMQ',
            quadrant: 'datamanagement',
            timeline: [
                {
                    moved: 0,
                    ringId: 'use',
                    date: new Date('2021-05-26'),
                    description:
                        'Our Main Queue System, used for almost everything',
                },
            ],
        },
        {
            links: [{ title: 'Couchbase', url: 'https://www.couchbase.com' }],
            key: 'couchbase',
            id: 'couchbase',
            title: 'Couchbase',
            quadrant: 'datamanagement',
            timeline: [
                {
                    moved: 0,
                    ringId: 'hold',
                    date: new Date('2021-05-26'),
                    description:
                        'We tried different cloud systems, but we are unable to guarantee good performance for our sync',
                },
            ],
        },
        {
            links: [{ title: 'Helm', url: 'https://helm.sh' }],
            key: 'helm',
            id: 'helm',
            title: 'Helm',
            quadrant: 'infrastructure',
            timeline: [
                {
                    moved: 0,
                    ringId: 'use',
                    date: new Date('2021-05-26'),
                    description:
                        'What else?',
                },
            ],
        },
        {
            links: [{ title: 'k6', url: 'https://k6.io' }],
            key: 'k6',
            id: 'k6',
            title: 'K6',
            quadrant: 'infrastructure',
            timeline: [
                {
                    moved: 0,
                    ringId: 'assess',
                    date: new Date('2021-05-28'),
                    description:
                        'We should generate a plugin for backspace for k6, something like the lighthouse plugin, to enable the backend developers to test out the performance using k6',
                },
            ],
        },
        {
            links: [{ title: 'Sentry', url: 'https://sentry.sport1dev.de' }],
            key: 'sentry',
            id: 'sentry',
            title: 'Sentry',
            quadrant: 'infrastructure',
            timeline: [
                {
                    moved: 0,
                    ringId: 'use',
                    date: new Date('2021-05-26'),
                    description:
                        'Errors, Performance, do you need something more?',
                },
            ],
        },
        {
            links: [{ title: 'SonarQube', url: 'https://sonar.sport1dev.de' }],
            key: 'sonarqube',
            id: 'sonarqube',
            title: 'SonarQube',
            quadrant: 'infrastructure',
            timeline: [
                {
                    moved: 0,
                    ringId: 'use',
                    date: new Date('2021-05-26'),
                    description:
                        'Gatekeeper and integrated in autodevops',
                },
            ],
        },
        {
            links: [{ title: 'Grafana', url: 'https://grafana.com' }],
            key: 'grafana',
            id: 'grafana',
            title: 'Grafana',
            quadrant: 'infrastructure',
            timeline: [
                {
                    moved: 0,
                    ringId: 'use',
                    date: new Date('2021-05-26'),
                    description:
                        'Main Dashboard system and with Loki, main logging system too...',
                },
            ],
        },
        {
            links: [{ title: 'KongHQ', url: 'https://konghq.com' }],
            key: 'kong',
            id: 'kong',
            title: 'Kong',
            quadrant: 'infrastructure',
            timeline: [
                {
                    moved: 0,
                    ringId: 'use',
                    date: new Date('2021-05-26'),
                    description:
                        'To reduce the hassle of configure authentication and custom code in every microservice and also to have a central point where we configure the aggregation of multiple api to generate an Edge. In a near future all the APIs will be delivered by Kong',
                },
            ],
        },
        {
            links: [{ title: 'OAuth2', url: 'https://oauth2-proxy.github.io/oauth2-proxy/' }],
            key: 'oauthproxy',
            id: 'oauthproxy',
            title: 'Oauth2 proxy',
            quadrant: 'infrastructure',
            timeline: [
                {
                    moved: 0,
                    ringId: 'trial',
                    date: new Date('2021-05-26'),
                    description:
                        'Instead of generating oauth code in every single backoffice, we can try to centralize it using oauth2proxy',
                },
            ],
        },
        {
            links: [{ title: 'k8sLens', url: 'https://k8slens.dev' }],
            key: 'lens',
            id: 'lens',
            title: 'Lens',
            quadrant: 'infrastructure',
            timeline: [
                {
                    moved: 0,
                    ringId: 'use',
                    date: new Date('2021-05-26'),
                    description:
                        'Do you need to check something in Kubernetes? You want to use your mouse instead of typing commands in kubectl? This is the right tool for you',
                },
            ],
        },
        {
            links: [{ title: 'Typescript', url: 'https://www.typescriptlang.org/' }],
            key: 'typeScript',
            id: 'typeScript',
            title: 'TypeScript',
            quadrant: 'languages-and-frameworks',
            timeline: [
                {
                    moved: 0,
                    ringId: 'use',
                    date: new Date('2021-05-26'),
                    description:
                        'Typescript or Javascript, we prefer Typescript, for now we used typescript mostly on Frontend and API, only in a few projects we access the DB',
                },
            ],
        },
        {
            key: 'java',
            id: 'java',
            title: 'Java',
            quadrant: 'languages-and-frameworks',
            timeline: [
                {
                    moved: 0,
                    ringId: 'use',
                    date: new Date('2021-05-26'),
                    description:
                        'Main Backend Language',
                },
            ],
        },
        {
            key: 'swift',
            id: 'swift',
            title: 'Swift',
            quadrant: 'languages-and-frameworks',
            timeline: [
                {
                    moved: 0,
                    ringId: 'assess',
                    date: new Date('2021-05-26'),
                    description:
                        'Backend Language used for OZ & Widgets',
                },
            ],
        },
        {
            links: [{ title: 'Spring.io', url: 'https://spring.io' }],
            key: 'spring',
            id: 'spring',
            title: 'Spring',
            quadrant: 'languages-and-frameworks',
            timeline: [
                {
                    moved: 0,
                    ringId: 'use',
                    date: new Date('2021-05-26'),
                    description:
                        'Main Java Framework',
                },
            ],
        },
        {
            links: [{ title: 'Spring Cloud', url: 'https://cloud.spring.io/spring-cloud-config/reference/html/' }],
            key: 'spring-config',
            id: 'spring-config',
            title: 'Spring Config Service',
            quadrant: 'languages-and-frameworks',
            timeline: [
                {
                    moved: 0,
                    ringId: 'hold',
                    date: new Date('2021-05-26'),
                    description:
                        'In the beginning looked like a good idea, but now is one of the main Single Point of Failure, furthermore our new Autodevops works quite good with env vars and we can save one dependency',
                },
            ],
        },
    ],
}

export class Sport1TechRadar implements TechRadarApi {
    async load(): Promise<TechRadarLoaderResponse> {
      // if needed id prop can be used to fetch the correct data

      const data = techRadar;

      // maybe you'll need to do some data transformation here to make it look like TechRadarLoaderResponse

      return Promise.resolve(data);
    }

  }
