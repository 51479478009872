import { createApiRef, DiscoveryApi } from '@backstage/core-plugin-api';
import fetch from 'cross-fetch';

export const cdn77ApiRef = createApiRef<CDN77API>({
  id: 'plugin.cdn77.service',
});

const DEFAULT_PROXY_PATH = '/cdn77/api';

type Options = {
  discoveryApi: DiscoveryApi;
  /**
   * Path to use for requests via the proxy, defaults to /cdn77/api
   */
  proxyPath?: string;
};

export class CDN77API {
  private readonly discoveryApi: DiscoveryApi;
  private readonly proxyPath: string;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
    this.proxyPath = options.proxyPath ?? DEFAULT_PROXY_PATH;
  }

  private async getUrls() {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    return {
      apiUrl: `${proxyUrl}${this.proxyPath}/v3/`,
      baseUrl: `${proxyUrl}${this.proxyPath}`,
    };
  }

  async purge(
    projectKey: string,
    path: string,
  ) {
    const { apiUrl } = await this.getUrls();

    const request = await fetch(`${apiUrl}cdn/${projectKey}/job/purge`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ paths: [path] })
    });
    if (!request.ok) {
      throw new Error(
        `failed to fetch data, status ${request.status}: ${request.statusText}`,
      );
    }
    const response = (await request.json()) as string;
    return JSON.stringify(response)
  }

}