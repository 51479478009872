import * as plugins from './plugins';

import {AlertDisplay, OAuthRequestDialog, SignInProviderConfig, SignInPage} from '@backstage/core-components';
import {AppRouter, FlatRoutes} from '@backstage/core-app-api';
import {
    CatalogEntityPage,
    CatalogIndexPage,
    catalogPlugin,
} from '@backstage/plugin-catalog';
import {
    CatalogGraphPage,
    catalogGraphPlugin,
} from '@backstage/plugin-catalog-graph';
import {
    CostInsightsLabelDataflowInstructionsPage,
    CostInsightsPage,
    CostInsightsProjectGrowthInstructionsPage,
} from '@backstage/plugin-cost-insights';
import {ExplorePage, explorePlugin} from '@backstage/plugin-explore';
import {Navigate, Route} from 'react-router';
import {
    TechDocsIndexPage,
    TechDocsReaderPage,
    techdocsPlugin,
} from '@backstage/plugin-techdocs';
import {UnifiedThemeProvider, themes} from '@backstage/theme';

import {apiDocsPlugin, ApiExplorerPage} from '@backstage/plugin-api-docs';
import {GraphiQLPage} from '@backstage/plugin-graphiql';
import React from 'react';
import {Root} from './components/Root';
import {SearchPage} from '@backstage/plugin-search';
import {TechRadarPage} from '@backstage/plugin-tech-radar';
import {UserSettingsPage} from '@backstage/plugin-user-settings';
import {apertureTheme} from './theme/aperture';
import {apis} from './apis';
import {createApp} from '@backstage/app-defaults';
import {entityPage} from './components/catalog/EntityPage';
import {orgPlugin} from '@backstage/plugin-org';
import {searchPage} from './components/search/SearchPage';
import {CssBaseline} from '@material-ui/core';
import {ScaffolderPage, scaffolderPlugin} from '@backstage/plugin-scaffolder';
import {
    CatalogImportPage,
    catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import {LighthousePage} from '@backstage/plugin-lighthouse'
import {Cdn77Page} from '@internal/plugin-cdn77';
import {FreezePage} from 'plugin-freeze';
import {KonePage} from '@internal/plugin-kone';
import {KoneDashboardPage} from '@internal/plugin-kone-dashboard';
import {StorybookPage} from '@internal/plugin-storybook';
import {gitlabAuthApiRef, microsoftAuthApiRef} from "@backstage/core-plugin-api";

const gitlabProvider: SignInProviderConfig = {
    id: 'gitlab-auth-provider',
    title: 'Sport1',
    message: 'Are you a developer? Do you have access to Gitlab? Then Sign-in',
    apiRef: gitlabAuthApiRef,
};
const azureProvider: SignInProviderConfig = {
    id: 'azure-auth-provider',
    title: 'Microsoft',
    message: 'Are you working by Sport1, Plazamedia, ... ? Then Sign-in',
    apiRef: microsoftAuthApiRef,
};

const app = createApp({
    apis,
    plugins: Object.values(plugins),
    components: {
        SignInPage: props => (
            <SignInPage
                {...props}
                auto
                providers={[gitlabProvider, azureProvider]}
            />
        ),
    },
    bindRoutes({bind}) {
        bind(catalogPlugin.externalRoutes, {
            createComponent: scaffolderPlugin.routes.root,
            viewTechDoc: techdocsPlugin.routes.docRoot,
        });
        bind(apiDocsPlugin.externalRoutes, {
            registerApi: scaffolderPlugin.routes.root,
        });
        bind(scaffolderPlugin.externalRoutes, {
            registerComponent: catalogImportPlugin.routes.importPage,
        });
        bind(explorePlugin.externalRoutes, {
            catalogEntity: catalogPlugin.routes.catalogEntity,
        });
        bind(catalogGraphPlugin.externalRoutes, {
            catalogEntity: catalogPlugin.routes.catalogEntity,
        });
        bind(orgPlugin.externalRoutes, {
            catalogIndex: catalogPlugin.routes.catalogIndex,
        });
    },
    themes: [
        {
            id: 'light',
            title: 'Light',
            variant: 'light',
            Provider: ({children}) => (
                <UnifiedThemeProvider theme={themes.light} children={children}/>
            ),
        },
        {
            id: 'dark',
            title: 'Dark',
            variant: 'dark',
            Provider: ({children}) => (
                <UnifiedThemeProvider theme={themes.dark} children={children}/>
            ),
        },
        {
            id: 'aperture',
            title: 'Aperture',
            variant: 'light',
            Provider: ({children}) => (
                <UnifiedThemeProvider theme={apertureTheme} noCssBaseline>
                    <CssBaseline/>
                    {children}
                </UnifiedThemeProvider>
            ),
        },
    ],
});

const routes = (
    <FlatRoutes>
        <Navigate key="/" to="catalog" replace/>
        <Route path="/api-docs" element={<ApiExplorerPage/>}/>
        <Route path="/catalog" element={<CatalogIndexPage/>}/>
        <Route
            path="/catalog/:namespace/:kind/:name"
            element={<CatalogEntityPage/>}
        >
            {entityPage}
        </Route>
        <Route path="/cost-insights" element={<CostInsightsPage/>}/>
        <Route
            path="/cost-insights/investigating-growth"
            element={<CostInsightsProjectGrowthInstructionsPage/>}
        />
        <Route
            path="/cost-insights/labeling-jobs"
            element={<CostInsightsLabelDataflowInstructionsPage/>}
        />
        <Route path="/docs" element={<TechDocsIndexPage/>}/>
        <Route
            path="/docs/:namespace/:kind/:name/*"
            element={<TechDocsReaderPage/>}
        />
        <Route path="/create" element={<ScaffolderPage/>}/>
        <Route path="/explore" element={<ExplorePage/>}/>
        <Route path="/graphiql" element={<GraphiQLPage/>}/>
        <Route path="/catalog-import" element={<CatalogImportPage/>}/>
        <Route path="/api-docs" element={<ApiExplorerPage/>}/>
        <Route
            path="/tech-radar"
            element={<TechRadarPage width={1500} height={800}/>}
        />
        <Route path="/freeze" element={<FreezePage/>}/>
        <Route path="/kone" element={<KonePage/>}/>
        <Route path="/lighthouse" element={<LighthousePage/>}/>
        <Route path="/kone" element={<KonePage/>}/>
        <Route path="/kone-dashboard" element={<KoneDashboardPage/>}/>
        <Route path="/storybook" element={<StorybookPage/>}/>
        <Route path="/catalog-import" element={<CatalogImportPage/>}/>
        <Route path="/search" element={<SearchPage/>}>
            {searchPage}
        </Route>
        <Route path="/settings" element={<UserSettingsPage/>}/>
        <Route path="/cdn77" element={<Cdn77Page/>}/>
        <Route path="/freeze" element={<FreezePage/>}/>
        <Route path="/catalog-graph" element={<CatalogGraphPage/>}/>
    </FlatRoutes>
);

export default app.createRoot(
    <>
        <AlertDisplay/>
        <OAuthRequestDialog/>
        <AppRouter>
            <Root>{routes}</Root>
        </AppRouter>
    </>,
);
