import { createPlugin, createRoutableExtension, createComponentExtension } from '@backstage/core-plugin-api';
import { Entity } from '@backstage/catalog-model';
import { WEB_PROJECT_KEY_ANNOTATION, IOS_PROJECT_KEY_ANNOTATION, ANDROID_PROJECT_KEY_ANNOTATION } from './components/useProjectKey';
import { rootRouteRef } from './routes';

export const storybookPlugin = createPlugin({
  id: 'storybook',
  routes: {
    root: rootRouteRef,
  },
});

export const isStorybookAvailable = (entity: Entity) =>
  Boolean(entity?.metadata?.annotations?.[WEB_PROJECT_KEY_ANNOTATION]) ||
  Boolean(entity?.metadata?.annotations?.[IOS_PROJECT_KEY_ANNOTATION]) ||
  Boolean(entity?.metadata?.annotations?.[ANDROID_PROJECT_KEY_ANNOTATION]) 
  ;

export const StorybookPage = storybookPlugin.provide(
  createRoutableExtension({
    component: () =>
      import('./components/StorybookPage').then(m => m.StorybookPage),
    mountPoint: rootRouteRef,
  }),
);

export const EntityStorybookCard = storybookPlugin.provide(
  createComponentExtension({
    component: {
      lazy: () =>
        import('./components/StorybookCard').then(m => m.StorybookCard),
    },
  }),
);