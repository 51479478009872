import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const freezePlugin = createPlugin({
  id: 'freeze',
  routes: {
    root: rootRouteRef,
  },
});

export const FreezePage = freezePlugin.provide(
  createRoutableExtension({
    name: 'FreezePage',
    component: () =>
      import('./components/GitLabProjectList').then(m => m.GitLabProjectList),
    mountPoint: rootRouteRef,
  }),
);
