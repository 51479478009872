export const exampleTools = [
  {
    title: 'Tech Radar',
    description:
      'Tech Radar is a list of technologies, complemented by an assessment result, called ring assignment.',
    url: '/tech-radar',
    image: '/tech-radar.png',
    tags: ['standards', 'landscape'],
  },
  // {
  //   title: 'Cost Insights',
  //   description: 'Insights into cloud costs for your organization.',
  //   url: '/cost-insights',
  //   image: '/google-cloud.png',
  //   tags: ['cloud', 'finops'],
  // },
  {
    title: 'Kubernetes Dashboard',
    description: 'Connect to our systems',
    url: '/kone-dashboard',
    image: '/kone-dashboard.jpg',
    tags: ['kubernetes', 'grafana', 'alertmanager', 'prometheus'],
  },
  {
    title: 'Kubernetes Configuration',
    description: 'Do you need to connect to our kubernetes clusters?',
    url: '/kone',
    image: '/kone-dashboard.jpg',
    tags: ['kubernetes', 'kubectl'],
  },
  {
    title: 'Sonarqube',
    description: 'Code Quality & Gatekeeper',
    url: 'https://sonarqube.sport1dev.de',
    image: '/sonarqube-logo.png',
    tags: ['sonarqube', 'q&a'],
  },
  {
    title: 'Jira',
    description: 'Issue & Project Tracking',
    url: 'https://sport1digital.atlassian.net',
    image: '/jira.jpg',
    tags: ['jira', 'issue', 'task', 'story'],
  },
  {
    title: 'Sentry',
    description: 'Realtime Error Reporting',
    url: 'https://sentry.sport1dev.de',
    image: '/sentry-logo.png',
    tags: ['errors', 'performance'],
  },
  {
    title: 'Squadcast',
    description: 'Centralized Error Reporting system, used for PostMortems and MTTA, MTTR',
    url: 'https://app.squadcast.com',
    image: '/squadcast-logo.png',
    tags: ['downtime', 'mtta', 'mttr'],
  },
  {
    title: 'Zeplin',
    description: 'Design intention and styleguides',
    url: 'https://app.zeplin.io',
    image: '/zeplin-logo.png',
    tags: ['design'],
  },
  {
    title: 'Gitlab',
    description: 'Sourcecontrol, CI/CD',
    url: 'https://gitlab.sport1dev.de',
    image: '/gitlab-logo.png',
    tags: ['code', 'git', 'ci', 'cd'],
  }
];


