import { Entity } from '@backstage/catalog-model';

export const CDN77_KEY_ANNOTATION = 'cdn77.com/id';
export const CDN77_KEY_STAGE_ANNOTATION = 'cdn77.com/stage-id';

export const isAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[CDN77_KEY_ANNOTATION]);

export const useKey = (entity: Entity) => {
  return entity?.metadata.annotations?.[CDN77_KEY_ANNOTATION] ?? '';
};

export const isStageAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[CDN77_KEY_STAGE_ANNOTATION]);

export const useStageKey = (entity: Entity) => {
  return entity?.metadata.annotations?.[CDN77_KEY_STAGE_ANNOTATION] ?? '';
};
