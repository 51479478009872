import { Entity } from '@backstage/catalog-model';
import { CDN77_KEY_ANNOTATION } from './components/useProjectKey';
import { rootRouteRef } from './routes';
import {
  configApiRef,
  createPlugin,
  createApiFactory,
  discoveryApiRef,
  createRoutableExtension
} from '@backstage/core-plugin-api';
import { cdn77ApiRef, CDN77API } from './api';

export const cdn77Plugin = createPlugin({
  id: 'cdn77',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: cdn77ApiRef,
      deps: { discoveryApi: discoveryApiRef, configApi: configApiRef },
      factory: ({ discoveryApi }) => {
        return new CDN77API({
          discoveryApi,
        });
      },
    }),
  ],
});

export const isCDN77Available = (entity: Entity) =>
  Boolean(entity?.metadata?.annotations?.[CDN77_KEY_ANNOTATION]) 
  ;

export const Cdn77Page = cdn77Plugin.provide(
  createRoutableExtension({
    name: 'Cdn77Page',
    component: () =>
      import('./components/CDN77Page').then(m => m.CDN77Page),
    mountPoint: rootRouteRef,
  }),
);
